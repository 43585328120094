import React from 'react';
import logo from './assets/trollface.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-title">
          Sim, o QuintaSerie.com existe. Ainda não está pronto. Problem?
        </p>  
        <p className="App-title">Daniel e Gabriel vão me ajudar... agora sai</p>
        <p>A vida é um lençol curto e por isso não dá pra perder tempo usando meias combinando.</p>
      </header>
    </div>
  );
}

export default App;
